<template>
  <div class="flex flex-col w-screen">
    <header
      class="fixed top-0 left-0 z-20 flex justify-between items-center px-edge gap-3xl w-full h-20 bg-skin-muted/10 text-skin-base backdrop-blur-xl"
    >
      <span
        class="fixed top-0 left-0 h-20 w-full bg-skin-muted opacity-20"
        inert
      ></span>
      <div class="relative text-heading-6 truncate w-full">
        Project Bathroom
      </div>

      <btn-mirage class="relative" />

      <nav class="relative flex justify-end items-center gap-2xl w-full">
        <btn-nav :title="'Concept'" />
        <btn-nav :title="'Find a store'" icon-right="store-locator" />
      </nav>
    </header>

    <div class="relative top-o left-0">
      <main class="relative flex items-stretch w-full min-h-[calc(100vh-52px)]">
        <nuxt-page
          :transition="{
            mode: 'out-in',
            css: false,
            onBeforeEnter: (el) => {
              $emit('onBeforeEnter', el);
            },
            onEnter: (el, done) => {
              onEnter(el, done);
              $emit('onEnter', el, done);
            },
            onAfterEnter: (el, done) => {
              onAfterEnter(el, done);
              $emit('onAfterEnter', el, done);
            },
            onEnterCancelled: (el) => {
              $emit('onEnterCancelled', el);
            },
            onBeforeLeave: (el) => {
              $emit('onBeforeLeave', el);
            },
            onLeave: onLeave,
            onAfterLeave: (el) => {
              $emit('onAfterLeave', el);
            },
            onLeaveCancelled: (el) => {
              $emit('onLeaveCancelled', el);
            },
          }"
        />
      </main>

      <template v-if="isTablet">
        <transition
          mode="out-in"
          enter-active-class="transition-transform duration-300 ease-out delay-200"
          enter-from-class="translate-y-full"
          enter-to-class="translate-y-0"
          leave-active-class="transition-transform duration-300 ease-in"
          leave-from-class="translate-y-0"
          leave-to-class="translate-y-full"
        >
          <div
            v-if="showCarousel"
            class="fixed z-2 top-20 left-0 bottom-0 right-0 w-full h-full"
          >
            <block-carousel-project-bathroom
              class="fixed top-0 left-0 w-full h-[calc(100vh-(80px+52px))]"
            />
          </div>
        </transition>
      </template>

      <footer
        class="relative flex justify-between z-2 items-center gap-3xl w-full h-13 min-h-13 max-h-13 px-edge bg-skin-muted text-skin-base"
      >
        <txt-copyright class="text-body-small" />
        <menu class="list-none flex gap-2xl">
          <li>
            <btn-nav
              size="small"
              class="self-start"
              :title="$t.labels.privacy_policy"
              :action="{ to: '/info-and-legal/privacy-policy' }"
            />
          </li>

          <li>
            <btn-nav
              size="small"
              class="self-start"
              :title="$t.labels.cookie_policy"
              :action="{ to: '/info-and-legal/cookie-policy' }"
            />
          </li>

          <li>
            <btn-nav
              size="small"
              class="self-start iubenda-cs-preferences-link"
              :title="$t.labels.review_cookie_settings"
            />
          </li>
        </menu>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { useRootStore } from "@/stores/root";

const route = useRoute();
const nuxtApp = useNuxtApp();
const rootStore = useRootStore();
const { setAppInert } = useGlobals();
const showCarousel = ref(route.name === "country-locale-project-bathroom-id");
const { start: modalBlockerStart, stop: modalBlockerStop } = useModalBlocker();
const isTablet = useMediaQuery("(min-width: 1020px)");
const onLeave = (el, done) => {
  done();
};

const onEnter = (el, done) => {
  done();
};

const onAfterEnter = (el) => {
  console.log("Bathmood onAfterEnter");

  if (!rootStore.needsScrollReset) {
    // back button navigation
    ScrollTrigger.refresh(true);
    rootStore.setNeedsScrollReset(true);
    setAppInert(false);
    return;
  }

  nuxtApp.$lenis.stop();

  ScrollTrigger.refresh(true);

  rootStore.setNeedsScrollReset(true);
  nuxtApp.$lenis.start();
  setAppInert(false);

  nuxtApp.$lenis.scrollTo(0, {
    force: true,
    lock: true,
    immediate: true,
    onComplete: () => {
      ScrollTrigger.refresh();
    },
  });
};

onMounted(() => {
  nuxtApp.$lenis.on("scroll", (e) => {
    ScrollTrigger.update();
  });

  ///////////////////////////////
  // Scroll blocker for modals //
  ///////////////////////////////

  modalBlockerStart(nuxtApp.$lenis, null);

  if (nuxtApp.$lenis.isStopped) {
    nuxtApp.$lenis.start();
  }
});

onBeforeUnmount(() => {
  nuxtApp.$lenis.off("scroll");
  nuxtApp.$lenis.stop();
  gsap.ticker.remove();
  modalBlockerStop();
});

watch(route, (newVal, oldVal) => {
  showCarousel.value = newVal.name === "country-locale-project-bathroom-id";
});
</script>
